import React from 'react'
import Layout from '../components/Layout'

const breadCrumbs = [
  {
  text: '404',
  link: false,
  }
]
const NotFoundPage = () => (
  <Layout title="404" breadCrumbs={breadCrumbs}>
    <div>
      <h1>NOT FOUND</h1>
      <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
    </div>
  </Layout>
)

export default NotFoundPage
